import { mapGetters, mapState } from "vuex";
import Stomp from "webstomp-client";
export default {
  name: "MapTracking",
  props: {
    id: {
      type: String,
      default: "map",
    },
    centerPosition: {
      type: Object,
      default: function() {
        return { lat: 24.7253981, lng: 46.3398977 };
      },
    },
    zoom: {
      type: Number,
      default: 8,
    },
  },
  data() {
    return {
      map: null,
      google: null,
      points: [],
      captainMarkers: [],
      setCenterOnce: true,
      stompOptions: {
        debug: false,
        heartbeat: {
          outgoing: 0,
          incoming: 20000,
        },
      },
      options: {
        libraries: [],
      },
    };
  },
  mounted() {
    this.initMap();
  },
  computed: {
    ...mapGetters([
      "getStompConnectionUrl",
      "getStompConnectionsTopics",
      "getStompConnectionToken",
    ]),
    ...mapGetters(["getMapPoints"]),
  },
  methods: {
    initMap() {
      this.$google
        .then((google) => {
          this.google = google;
          this.map = new this.google.maps.Map(
            document.getElementById(this.id),
            {
              center: this.getCenterPosition(),
              zoom: this.zoom,
              zoomControl: false,
              streetViewControl: false,
              mapTypeControl: false
            }
          );
          this.prepareItems();
          this.connect();
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    },
    connect() {
      this.stompClient = Stomp.client(
        this.getStompConnectionUrl,
        this.stompOptions
      );
      this.stompClient.connect(
        { "X-Authorization": `Bearer ${this.getStompConnectionToken}` },
        (frame) => {
          for (const topic of this.getStompConnectionsTopics) {
            this.stompClient.subscribe(topic, (tick) => {
              let responseBody = JSON.parse(tick.body);
              let ourMarker = {
                latitude: responseBody.latitude,
                longitude: responseBody.longitude,
                type: "CAPTAIN",
                id: responseBody.captainId
              };
              let existingMarker = this.captainMarkers.find(item => item.id === ourMarker.id);
              if (existingMarker === null || existingMarker == undefined) {
                this.addMarker(ourMarker);
              } else {
                this.animateMarker(existingMarker, 0.5, ourMarker);
              }
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    setMapCenter(position) {
      if (this.map) {
        this.map.setCenter(position ? position : this.getCenterPosition());
      }
    },
    setPoints() {
      let points = [];
      Object.keys(this.getMapPoints).forEach((key) => {
        let item = this.getMapPoints[key];
        points.push({
          0: item.longitude,
          1: item.latitude,
        });
      });
      this.points = points;
    },
    addMarkers() {
      Object.keys(this.getMapPoints).forEach((key) => {
        this.addMarker(this.getMapPoints[key]);
      });
    },
    addMarker(options) {
      if (this.map) {
        var icon = {
          url: this.getIconByType(options.type), // url
          scaledSize: new google.maps.Size(40, 40), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(20, 20), // anchor
        };
        let marker = new this.google.maps.Marker({
          position: { lat: options.latitude, lng: options.longitude },
          map: this.map,
          icon: icon,
          id: options.id
        });
        if (options.type === "CAPTAIN") {
          this.captainMarkers.push(marker)
          this.map.panTo(marker.getPosition());
          this.smoothZoom(16);
        }
      }
    },
    smoothZoom(zoomVal) {
      var vm = this;
      if(vm.map.getZoom() != zoomVal) {
          setTimeout(function(){vm.setMapZoom(zoomVal);}, 150);
      }
    },
    animateMarker(marker, duration, moveto) {
      if(this.setCenterOnce){
          this.map.panTo(marker.getPosition());
          this.setCenterOnce = false;
      }
      var deltalat = (moveto.latitude - marker.position.lat()) / 100,
        deltalng = (moveto.longitude - marker.position.lng()) / 100,
        delay = 10 * duration;
      for (var i = 0; i < 100; i++) {
        (function(duration_period) {
          setTimeout(function() {
            var lat = marker.position.lat();
            var lng = marker.position.lng();
            lat += deltalat;
            lng += deltalng;
            var latlng = new google.maps.LatLng(lat, lng);
            marker.setPosition(latlng);
          }, delay * duration_period);
        })(i);
      }
    },
    getIconByType(type) {
      if (this.map) {
        let icons = {
          PICK_UP                 : require("@/assets/theme/icons/task-types/PICK_UP.png"),
          ON_DEMAND_PICK_UP       : require('@/assets/theme/icons/task-types/PICK_UP.png'),
          DELIVERY                : require("@/assets/theme/icons/task-types/DELIVERY.png"),
          CAPTAIN                 : require("@/assets/theme/icons/task-types/CAPTAIN.png"),
          DEFAULT                 : require("@/assets/theme/icons/task-types/PICK_UP.png"),
        };
        return icons[type] ?? icons["DEFAULT"];
      }
    },
    setMapZoom(zoom) {
      if (this.map) {
        this.map.setZoom(zoom ? zoom : this.zoom);
      }
    },
    prepareItems() {
      this.setPoints();
      this.addMarkers();
      this.setMapCenter();
      this.setMapZoom(13);
    },
    getCenterPosition() {
      if (this.points.length) {
        let max_x = Math.max.apply(
          Math,
          this.points.map(function(point) {
            return point[1];
          })
        );
        let min_x = Math.min.apply(
          Math,
          this.points.map(function(point) {
            return point[1];
          })
        );

        let max_y = Math.max.apply(
          Math,
          this.points.map(function(point) {
            return point[0];
          })
        );
        let min_y = Math.min.apply(
          Math,
          this.points.map(function(point) {
            return point[0];
          })
        );

        return { lat: (max_x + min_x) / 2, lng: (max_y + min_y) / 2 };
      }
      return this.centerPosition;
    },
  },
};
